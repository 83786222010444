import * as React from 'react'
import DefaultLayout from 'layouts/Default'
import { graphql } from 'gatsby'
import HeroWithBg from 'components/common/HeroWithBg'
import SubPgSection from 'components/pg-underwriting/SubPgSection'
import UnderwritingForms from 'components/pg-underwriting/UnderwritingForms'

const UnderwritingSubPg = ({ data }) => {
  const { sanityUnderwritingSubPg: pg } = data

  return (
    <DefaultLayout {...pg.seo}>
      <HeroWithBg {...pg.hero} />

      {pg.sections?.map((section, key) => (
        <SubPgSection {...section} key={key} />
      ))}

      {pg.title === 'Forms' && <UnderwritingForms />}
    </DefaultLayout>
  )
}

export default UnderwritingSubPg

export const query = graphql`query UnderwritingSubPg($id: String) {
  sanityUnderwritingSubPg(id: {eq: $id}) {
    title
    hero { ...hero }
    sections { ...hero }
    seo {
      title
      description
    }
  }
}`
