import * as React from 'react'
import * as css from './UnderwritingForms.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import { GrDocumentPdf } from 'react-icons/gr'
import newtab from 'utils/newtab'

const allCategories = [
  'Request for Medical Information',
  'Informal Inquiry',
  'Standard Underwriting Questionnaires',
  'Quick Quote Questionnaires',
]

const UnderwritingForms = () => {
  const allForms = useStaticQuery(query).allSanityUnderwritingForms.nodes
  const filteredForms = category => allForms
    .filter(form => form.category === category)
    .sort((a, b) => a.title > b.title)

  return (
    <section className={`${css.root} container padding`}>
      {allCategories.map((category, key) => (
        <section className={`${css.category} richtext`} key={key}>
          <h2 className="h2">{category}</h2>
          <ul>
            {filteredForms(category).map((form, key) => (
              <li key={key}>
                <a className="link with-icon" href={form.file.asset.url} {...newtab}>
                  <GrDocumentPdf />
                  {form.title}
                </a>
                {form.notes && <small className={css.notes}>{form.notes}</small>}
              </li>
            ))}
          </ul>

          {key < allCategories.length-1 && (
            <hr className="lg" />
          )}
        </section>
      ))}
    </section>
  )
}

export default UnderwritingForms

const query = graphql`query UnderwritingForms {
  allSanityUnderwritingForms {
    nodes {
      title
      notes
      category
      file { asset { url } }
    }
  }
}`
