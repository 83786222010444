import * as React from 'react'
import * as css from './SubPgSection.module.css'
import SanityBlock from 'components/common/SanityBlock'

const SubPgSection = ({ title, _rawDescription }) => (
  <section className={`${css.root} container padding-x`}>
    <div className={`${css.inner} padding-y richtext`}>
      {title && <h2 className="h2">{title}</h2>}
      {_rawDescription && <SanityBlock body={_rawDescription} />}
    </div>
  </section>
)


export default SubPgSection
